import axios, { AxiosError } from 'axios';
import { getAccessToken, getAccount, getIdToken, login } from '../auth';
import {
  X_MS_TOKEN_AAD_ID_TOKEN,
  X_MS_TOKEN_AAD_ACCESS_TOKEN,
  CANCELED_ERROR,
  AUTH_SERVICE,
} from '../utils/constants';
import { isProd, isTest } from '../utils/isEnv';
import AppInsights from '../applicationInsights';
import { DSAInsightModel } from '../types/home.types';
import isError from '../utils/isError';
import { ErrorMessage } from './types';

/**
 * Hub API
 */
export const hubAPI = axios.create({
  baseURL: isProd() ? process.env.REACT_APP_API_BASE_URL : '',
});

/**
 * Add interceptors if not testing
 */
if (!isTest()) {
  const handleOnRejected = (error: AxiosError<ErrorMessage>) => {
    // Format `error.message` so `error.slice` can have access to it.
    // `error.request` already has `message` property
    if (error.response) {
      error.code = error.response.status.toString();
      error.message = error.response.data.message;
    }

    error.name !== CANCELED_ERROR && AppInsights.trackException(error);
    throw error;
  };

  if (process.env.REACT_APP_AUTH_SERVICE === AUTH_SERVICE.MSAL) {
    hubAPI.interceptors.request.use(async config => {
      const account = getAccount();

      //if account is null, user is not logged in
      if (!account) await login();

      const accessToken = await getAccessToken(account);
      const idToken = await getIdToken(account);

      if (accessToken && idToken) {
        config.headers[X_MS_TOKEN_AAD_ID_TOKEN] = `Bearer ${idToken}`;
        config.headers[X_MS_TOKEN_AAD_ACCESS_TOKEN] = `Bearer ${accessToken}`;
      } else await login();

      return config;
    }, handleOnRejected);
  }

  hubAPI.interceptors.response.use(res => res, handleOnRejected);
}

/**
 * Add view api for tracking number of views
 */
const addView = async (contentId: string, signal: AbortSignal) => {
  try {
    await hubAPI.patch('/api/views', { content: contentId }, { signal });
  } catch (error) {}
};

/**
 * Add search data for search analysis for queries >= 2 characters
 */
const addSearchData = async (query: string, content: string) => {
  try {
    if (query.trim().length < 2) return;

    await hubAPI.post('/api/searches', { query, content });
  } catch (error) {}
};

/**
 * TEMPORARY: Add data collection when `ContentItem` `setTimeout` expires
 */
const addAuthAnalytics = async (
  idFromSource: string,
  isDesktop: boolean,
  signal: AbortSignal
) => {
  try {
    await hubAPI.post(
      '/api/auth-analytics',
      { idFromSource, isDesktop },
      { signal }
    );
  } catch (error) {}
};

/**
 * Fetch DSA Insights post
 */
type DSAInsightsTuple = [DSAInsightModel, null] | [null, boolean];
const fetchDSAInsightsPost = async (
  slug: string,
  signal: AbortSignal
): Promise<DSAInsightsTuple> => {
  try {
    const { data } = await hubAPI.get<DSAInsightModel>(
      `/api/dsa-insights/${slug}`,
      {
        signal,
      }
    );

    return [data, null];
  } catch (error) {
    const shouldNavigate = isError(error);
    return [null, shouldNavigate];
  }
};

const api = {
  addView,
  addSearchData,
  addAuthAnalytics,
  fetchDSAInsightsPost,
};

export default api;
