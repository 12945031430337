import React, { ReactElement, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { store } from './state/store';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import Spinner from './components/Spinner/Spinner';
import AppInit from './components/AppInit/AppInit';
import CookiesCheck from './components/CookiesCheck/CookiesCheck';
import GlobalLoading from './components/GlobalLoading/GlobalLoading';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import AnalyticsInit from './components/AnalyticsInit/AnalyticsInit';
import './index.css';
import { AUTH_SERVICE } from './utils/constants';

const renderApp = (content: ReactElement) => {
  const container = document.getElementById('root')!;
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ErrorBoundary>
          <CookiesCheck>
            <Provider store={store}>
              <GlobalLoading />
              <Suspense fallback={<GlobalLoading isSuspenseFallback={true} />}>
                <AnalyticsInit>{content}</AnalyticsInit>
              </Suspense>
            </Provider>
          </CookiesCheck>
        </ErrorBoundary>
      </BrowserRouter>
    </React.StrictMode>
  );
};

(async () => {
  if (process.env.REACT_APP_AUTH_SERVICE === AUTH_SERVICE.MSAL) {
    const { msalInit, PCA, scopes } = await import('./auth');
    await msalInit();

    renderApp(
      <MsalProvider instance={PCA.instance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{ scopes }}
          loadingComponent={Spinner.LargeFullScreen}
        >
          <AppInit>
            <App />
          </AppInit>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    );
  } else {
    renderApp(
      <AppInit>
        <App />
      </AppInit>
    );
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
