import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATE_STATUSES } from '../../utils/constants';
import { RootState } from '../store';
import { ObjectIdString, WithObjectId } from '../../types';
import {
  AdminUpdateNotification,
  AdminStagedNotification,
  AdminNotification,
} from '../../types/adminNotifications.types';
import adminNotificationsAPI from './adminNotifications.api';

export interface AdminNotifications {
  notifications: WithObjectId<AdminNotification>[][];
}

export interface AdminNotificationState {
  status: STATE_STATUSES;
  data: AdminNotifications;
}

export const initialState: AdminNotificationState = {
  status: STATE_STATUSES.INITIAL,
  data: {
    notifications: [
      [], // active notifications
      [], // expired notifications
    ],
  },
};

export const fetchAdminNotifications = createAsyncThunk(
  'adminNotifications/fetchAdminNotifications',
  async (_, thunkAPI) => {
    const response = await adminNotificationsAPI.fetchAdminNotifications(
      thunkAPI.signal
    );
    return response.data;
  }
);

export const createNotification = createAsyncThunk(
  'adminNotifications/createNotification',
  async (notification: AdminStagedNotification, thunkAPI) => {
    const response = await adminNotificationsAPI.createNotification(
      notification,
      thunkAPI.signal
    );
    return response.data;
  }
);

export const updateNotification = createAsyncThunk(
  'adminNotifications/updateNotification',
  async (notificationPayload: AdminUpdateNotification, thunkAPI) => {
    const response = await adminNotificationsAPI.updateNotification(
      notificationPayload,
      thunkAPI.signal
    );
    return response.data;
  }
);

export const deleteNotifications = createAsyncThunk(
  'adminNotifications/deleteNotifications',
  async (notifications: { notifications: ObjectIdString[] }, thunkAPI) => {
    const response = await adminNotificationsAPI.deleteNotifications(
      notifications,
      thunkAPI.signal
    );
    return response.data;
  }
);

export const adminNotificationsSlice = createSlice({
  name: 'adminNotifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAdminNotifications.pending, state => {
        state.status = STATE_STATUSES.PENDING;
      })
      .addCase(fetchAdminNotifications.fulfilled, (state, action) => {
        const { notifications } = action.payload;
        return {
          status: STATE_STATUSES.FULFILLED,
          data: {
            ...state.data,
            notifications,
          },
        };
      })
      .addCase(fetchAdminNotifications.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.status = STATE_STATUSES.REJECTED;
        }
      })
      .addMatcher(
        action =>
          [
            createNotification.pending.type,
            updateNotification.pending.type,
            deleteNotifications.pending.type,
          ].includes(action.type),
        state => {
          state.status = STATE_STATUSES.PENDING;
        }
      );
  },
});
export const adminNotificationsReducer = adminNotificationsSlice.reducer;

export const selectAdminNotifications = (state: RootState) =>
  state.adminNotifications.data;

export const selectIsAdminNotificationsRejected = (state: RootState) =>
  state.adminNotifications.status === STATE_STATUSES.REJECTED;
