import Fuse from 'fuse.js';
import {
  SyntheticEvent,
  useEffect,
  useState,
  useRef,
  MutableRefObject,
} from 'react';
import {
  DEBOUNCE_TIMEOUT,
  fuseQueryAndTagsOptions,
  EVENTS,
  TAGS_SEARCH_INPUT,
  ADMIN,
} from '../../utils/constants';
import debounce from '../../utils/debounce';
import curriedHandleUpAndDownArrowKeys from '../../utils/curriedHandleUpAndDownArrowKeys';

interface Suggestion {
  tag: string;
}

interface TagSuggestionsProps {
  inputValue: string;
  handleSuggestionClick: (e: SyntheticEvent) => void;
  tags: string[];
  inputRef: MutableRefObject<HTMLInputElement | null>;
  handlePressEnterOnSuggestion: (selectedVal: string) => void;
}

export const TAG_SUGGESTIONS = 'tag-suggestions';

/**
 * Tag suggestions dropdown for `FilterByTagsTakeover` and `TagSearch`
 */
const TagSuggestions = ({
  inputValue,
  handleSuggestionClick,
  tags,
  inputRef,
  handlePressEnterOnSuggestion,
}: TagSuggestionsProps) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const ulRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const mapSearchResultsToSuggestions = () => {
      const fuseResults = new Fuse(tags, fuseQueryAndTagsOptions)
        .search(inputValue)
        .map(result => ({ tag: result.item }));

      if (fuseResults.length > 0) setSuggestions(fuseResults);
      else setSuggestions([{ tag: ADMIN.TAGS.NO_TAGS_FOUND }]);
    };

    const getDebouncedSuggestions = debounce(
      mapSearchResultsToSuggestions,
      DEBOUNCE_TIMEOUT
    );

    inputValue.length >= 2 && getDebouncedSuggestions();

    return () => getDebouncedSuggestions.cancel();
  }, [inputValue, tags]);

  useEffect(() => {
    const handleUpAndDownArrowKeys = curriedHandleUpAndDownArrowKeys(
      ulRef,
      inputRef,
      true,
      handlePressEnterOnSuggestion,
      'li'
    );
    window.addEventListener(EVENTS.KEYUP, handleUpAndDownArrowKeys);
    return () =>
      window.removeEventListener(EVENTS.KEYUP, handleUpAndDownArrowKeys);
  }, [inputRef, handlePressEnterOnSuggestion]);

  // if there are no suggestions yet, short circuit to avoid rendering an empty container
  if (!suggestions.length) return null;

  return (
    <div onClick={handleSuggestionClick} data-testid={TAG_SUGGESTIONS}>
      <ul
        ref={ulRef}
        role='listbox'
        aria-labelledby={TAGS_SEARCH_INPUT}
        className='thin-scrollbar absolute z-10 max-h-44 w-full overflow-y-auto rounded-2xl bg-brand-white py-2 shadow-lg hover:cursor-pointer'
      >
        {suggestions.map(({ tag }, i) => (
          <li
            tabIndex={0}
            role='option'
            aria-selected={true}
            key={i}
            data-value={tag}
            className='w-full p-2 hover:bg-brand-tint-grey-1'
          >
            {tag}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TagSuggestions;
