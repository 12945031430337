import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ObjectIdString, WithObjectId, WithTimestamps } from '../../types';
import { STATE_STATUSES } from '../../utils/constants';
import { RootState } from '../store';
import { AIChatUpdateBody } from '../../types/aiChats.types';
import aiChatsAPI, { AIChatDoc } from './aiChats.api';

const aiMessageVizDataLibs = ['vega'];

interface AIMessageFeedback {
  value: boolean;
  message: string;
}
export interface AIChatFeedbackPayload {
  chatID: string;
  messageId: string;
  feedback: {
    value: boolean;
    message: string;
  };
}

export interface AIChatMessage {
  isUser: boolean;
  message: string;
  feedback?: AIMessageFeedback;
  vizData?: VizData[];
}

export interface VizData {
  lib: (typeof aiMessageVizDataLibs)[number];
  data: unknown;
}

export interface AIChat {
  title: string;
  isDeleted: boolean;
  messages: WithObjectId<WithTimestamps<AIChatMessage>>[];
}

interface AIChatsInFlightRequests {
  // keep track of which chat ID's are inflight
  aiChats: Record<ObjectIdString, true>;
  // keep track of which message ID's are inflight (for feedback)
  aiMessages: Record<ObjectIdString, true>;
}

export interface AIMessageWithChatID {
  message: AIChatMessage['message'];
  chatID: string;
}

export interface AIChatsState {
  statuses: {
    fetchChatsStatus: STATE_STATUSES;
    updateChatStatus: STATE_STATUSES;
    deleteChatsStatus: STATE_STATUSES;
    fetchMessagesStatus: STATE_STATUSES;
    postMessageStatus: STATE_STATUSES;
    updateMessageFeedbackStatus: STATE_STATUSES;
  };
  data: AIChatDoc[];
  inFlightRequests: AIChatsInFlightRequests;
  activeChat: {
    chatID: ObjectIdString;
    chatTitle: AIChatDoc['title'];
  };
  newMessage: string;
  newLLMMessageID: string;
  isTextInputFocused: boolean;
}

export const initialState: AIChatsState = {
  statuses: {
    fetchChatsStatus: STATE_STATUSES.INITIAL,
    updateChatStatus: STATE_STATUSES.INITIAL,
    deleteChatsStatus: STATE_STATUSES.INITIAL,
    fetchMessagesStatus: STATE_STATUSES.INITIAL,
    postMessageStatus: STATE_STATUSES.INITIAL,
    updateMessageFeedbackStatus: STATE_STATUSES.INITIAL,
  },
  data: [],
  inFlightRequests: {
    aiChats: {},
    aiMessages: {},
  },
  activeChat: {
    chatID: '',
    chatTitle: '',
  },
  newMessage: '',
  newLLMMessageID: '',
  isTextInputFocused: true,
};

export const fetchAIChats = createAsyncThunk(
  'aiChats/fetchAIChats',
  async (_, thunkAPI) => {
    const response = await aiChatsAPI.fetchAIChats(thunkAPI.signal);
    return response.data;
  }
);

export const updateAIChat = createAsyncThunk(
  'aiChats/updateAIChat',
  async (chat: AIChatUpdateBody, thunkAPI) => {
    const response = await aiChatsAPI.updateAIChat(chat, thunkAPI.signal);
    return response.data;
  }
);

export const deleteAllAIChats = createAsyncThunk(
  'aiChats/deleteAllAIChats',
  async (_, thunkAPI) => {
    const response = await aiChatsAPI.deleteAllAIChats(thunkAPI.signal);
    return response.data;
  }
);

export const fetchAIChatMessages = createAsyncThunk(
  'aiChats/fetchAIChatMessages',
  async (chatID: AIChatDoc['_id'], thunkAPI) => {
    const response = await aiChatsAPI.fetchAIChatMessages(
      chatID,
      thunkAPI.signal
    );
    return response.data;
  }
);

export const postAIChatMessage = createAsyncThunk(
  'aiChats/postAIChatMessage',
  async (data: AIMessageWithChatID, thunkAPI) => {
    const response = await aiChatsAPI.postAIChatMessage(data, thunkAPI.signal);
    return response.data;
  }
);

export const updateAIChatMessageFeedback = createAsyncThunk(
  'aiChats/updateAIChatMessageFeedback',
  async (data: AIChatFeedbackPayload, thunkAPI) => {
    const response = await aiChatsAPI.updateAIChatMessageFeedback(
      data,
      thunkAPI.signal
    );
    return response.data;
  }
);

export const aiChatsSlice = createSlice({
  name: 'aiChats',
  initialState,
  reducers: {
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    // optimistically display user's message for existing chat
    setUserMessage: (state, action) => {
      const { chatID, message } = action.payload;
      const targetChat = state.data.find(chat => chat._id === chatID);
      state.newLLMMessageID = '';
      if (targetChat) {
        targetChat.messages.push({
          _id: 'optimistic-user-message',
          isUser: true,
          message: message,
          createdAt: '',
          updatedAt: '',
        });
      }
    },
    // optimistically display user's message if new chat
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
    setNewLLMMessageID: (state, action) => {
      state.newLLMMessageID = action.payload;
    },
    setTextInputFocus: (state, action) => {
      state.isTextInputFocused = action.payload;
    },
    setEmptyMessages: state => {
      state.data.forEach(chat => {
        chat.messages = [];
      });
    },
  },
  extraReducers: builder => {
    builder
      // fetch chats
      .addCase(fetchAIChats.pending, state => {
        state.statuses.fetchChatsStatus = STATE_STATUSES.PENDING;
      })
      .addCase(fetchAIChats.fulfilled, (state, action) => {
        state.statuses.fetchChatsStatus = STATE_STATUSES.FULFILLED;
        state.data = action.payload.userChats;
      })
      .addCase(fetchAIChats.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.statuses.fetchChatsStatus = STATE_STATUSES.REJECTED;
        }
      })
      // update a chat to update title or flag deletion
      .addCase(updateAIChat.pending, (state, action) => {
        state.statuses.updateChatStatus = STATE_STATUSES.PENDING;
        const { arg } = action.meta;
        state.inFlightRequests.aiChats[arg._id] = true;

        if (arg.isDeleted) {
          state.statuses.deleteChatsStatus = STATE_STATUSES.PENDING;
        }
      })
      .addCase(updateAIChat.fulfilled, (state, action) => {
        const updatedChat = action.payload;
        const { arg } = action.meta;

        state.data = state.data.reduce(
          (acc, cur) => {
            if (cur._id === updatedChat._id) {
              if (updatedChat.isDeleted) {
                return acc;
              }
              acc.push(updatedChat);
            } else {
              acc.push(cur);
            }
            return acc;
          },
          [] as AIChatsState['data']
        );

        state.statuses.updateChatStatus = STATE_STATUSES.FULFILLED;
        if (arg.isDeleted) {
          state.statuses.deleteChatsStatus = STATE_STATUSES.FULFILLED;
          state.activeChat.chatID = '';
          state.activeChat.chatTitle = '';
        } else {
          state.activeChat.chatTitle = updatedChat.title;
        }
        delete state.inFlightRequests.aiChats[arg._id];
      })
      .addCase(updateAIChat.rejected, (state, action) => {
        if (!action.meta.aborted) {
          const { arg } = action.meta;
          delete state.inFlightRequests.aiChats[arg._id];
          state.statuses.updateChatStatus = STATE_STATUSES.REJECTED;
          if (arg.isDeleted) {
            state.statuses.deleteChatsStatus = STATE_STATUSES.REJECTED;
          }
        }
      })
      // delete all chats
      .addCase(deleteAllAIChats.pending, state => {
        state.statuses.deleteChatsStatus = STATE_STATUSES.PENDING;

        state.data.forEach(({ _id }) => {
          state.inFlightRequests.aiChats[_id] = true;
        });
      })
      .addCase(deleteAllAIChats.fulfilled, state => {
        state.statuses.deleteChatsStatus = STATE_STATUSES.FULFILLED;
        state.data = [];
        state.inFlightRequests.aiChats = {};
        state.activeChat.chatID = '';
        state.activeChat.chatTitle = '';
      })
      .addCase(deleteAllAIChats.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.statuses.deleteChatsStatus = STATE_STATUSES.REJECTED;
          state.inFlightRequests.aiChats = {};
        }
      })
      // fetch messages
      .addCase(fetchAIChatMessages.pending, state => {
        state.statuses.fetchMessagesStatus = STATE_STATUSES.PENDING;
      })
      .addCase(fetchAIChatMessages.fulfilled, (state, action) => {
        const updatedData = state.data.map(chat => {
          if (chat._id === action.meta.arg) {
            return {
              ...chat,
              messages: action.payload.chatMessages,
            };
          }
          return {
            ...chat,
            messages: [], // Empty array for other chat messages
          };
        });

        state.statuses.fetchMessagesStatus = STATE_STATUSES.FULFILLED;
        state.data = updatedData;
        state.newLLMMessageID = '';
      })
      .addCase(fetchAIChatMessages.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.statuses.fetchMessagesStatus = STATE_STATUSES.REJECTED;
        }
      })
      // post a message
      .addCase(postAIChatMessage.pending, (state, action) => {
        state.inFlightRequests.aiChats[action.meta.arg.chatID] = true;
        state.statuses.postMessageStatus = STATE_STATUSES.PENDING;
      })
      .addCase(postAIChatMessage.fulfilled, (state, action) => {
        delete state.inFlightRequests.aiChats[action.meta.arg.chatID];
        state.statuses.postMessageStatus = STATE_STATUSES.FULFILLED;

        // add new chat to state if it exists
        if (action.payload.newChat) {
          state.data.unshift(action.payload.newChat);
          state.data[0].messages = [
            action.payload.userMessage,
            action.payload.llmMessage,
          ];
          state.newMessage = '';
          state.activeChat.chatID = action.payload.newChat._id;
          state.activeChat.chatTitle = action.payload.newChat.title;
        } else {
          // replace state having user's placeholder message with db data
          const idx = state.data.findIndex(
            obj => obj._id === action.meta.arg.chatID
          );
          if (idx !== -1) {
            state.data[idx].messages.splice(
              state.data[idx].messages.length - 1,
              1,
              action.payload.userMessage,
              action.payload.llmMessage
            );
          }
        }
        state.newLLMMessageID = action.payload.llmMessage._id;
      })
      .addCase(postAIChatMessage.rejected, (state, action) => {
        if (!action.meta.aborted) {
          delete state.inFlightRequests.aiChats[action.meta.arg.chatID];
          state.statuses.postMessageStatus = STATE_STATUSES.REJECTED;
          if (state.newMessage) {
            // clear user's optimistic message for a new chat
            state.newMessage = '';
          } else {
            // remove user's message from state (pop) for existing chat
            const idx = state.data.findIndex(
              obj => obj._id === action.meta.arg.chatID
            );
            if (idx !== -1) {
              state.data[idx].messages.pop();
            }
          }
        }
      })
      // update message with feedback
      .addCase(updateAIChatMessageFeedback.pending, (state, action) => {
        state.statuses.updateMessageFeedbackStatus = STATE_STATUSES.PENDING;
        const { arg } = action.meta;
        state.inFlightRequests.aiMessages[arg.messageId] = true;
      })
      .addCase(updateAIChatMessageFeedback.fulfilled, (state, action) => {
        const { chatID, messageId } = action.meta.arg;
        const feedback = action.payload.feedback;
        const updatedData = state.data.map(chat => {
          if (chat._id === chatID) {
            return {
              ...chat,
              messages: chat.messages.map(msg =>
                msg._id === messageId ? { ...msg, feedback } : msg
              ),
            };
          }
          return chat;
        });
        state.data = updatedData;
        state.statuses.updateMessageFeedbackStatus = STATE_STATUSES.FULFILLED;
        delete state.inFlightRequests.aiMessages[messageId];
      })
      .addCase(updateAIChatMessageFeedback.rejected, (state, action) => {
        if (!action.meta.aborted) {
          const { messageId } = action.meta.arg;
          state.statuses.updateMessageFeedbackStatus = STATE_STATUSES.REJECTED;
          delete state.inFlightRequests.aiMessages[messageId];
        }
      });
  },
});

export const aiChatsReducer = aiChatsSlice.reducer;

export const selectAIChatsStatus = (state: RootState) => state.aiChats.statuses;

export const selectIsAIChatsFetching = (state: RootState) =>
  [STATE_STATUSES.INITIAL, STATE_STATUSES.PENDING].includes(
    state.aiChats.statuses.fetchChatsStatus
  );

export const selectAIChatsInFlight = (state: RootState) =>
  state.aiChats.inFlightRequests.aiChats;

export const selectAIChatMessagesInFlight = (state: RootState) =>
  state.aiChats.inFlightRequests.aiMessages;

export const selectAIChats = (state: RootState) => state.aiChats.data;

export const selectAIChatMessages =
  (chatID: ObjectIdString) => (state: RootState) =>
    state.aiChats.data.find(({ _id }) => _id === chatID);

export const selectAIChatsChatID = (state: RootState) =>
  state.aiChats.activeChat.chatID;

export const selectAIChatsChatTitle = (state: RootState) =>
  state.aiChats.activeChat.chatTitle;

export const selectAIChatMessagesFetching = (state: RootState) =>
  [STATE_STATUSES.PENDING].includes(state.aiChats.statuses.fetchMessagesStatus);

export const selectAIChatNewMessage = (state: RootState) =>
  state.aiChats.newMessage;

export const selectIsAIChatMessagePending = (state: RootState) =>
  state.aiChats.statuses.postMessageStatus === STATE_STATUSES.PENDING;

export const selectNewLLMMessageID = (state: RootState) =>
  state.aiChats.newLLMMessageID;

export const selectIsTextInputFocused = (state: RootState) =>
  state.aiChats.isTextInputFocused;

export const {
  setActiveChat,
  setUserMessage,
  setNewMessage,
  setNewLLMMessageID,
  setTextInputFocus,
  setEmptyMessages,
} = aiChatsSlice.actions;
