import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import { loadingReducer } from './loading/loading.slice';
import { configReducer } from './config/config.slice';
import { errorReducer } from './error/error.slice';
import { widgetsReducer } from './widgets/widgets.slice';
import { userReducer } from './user/user.slice';
import { homeReducer } from './home/home.slice';
import { contentReducer } from './content/content.slice';
import { toastReducer } from './toast/toast.slice';
import { faqsReducer } from './faq/faqs.slice';
import { dsaTeamsReducer } from './dsaTeams/dsaTeams.slice';
import { dsaInsightsReducer } from './dsaInsights/dsaInsights.slice';
import { aiChatsReducer } from './aiChats/aiChats.slice';
import { adminNotificationsReducer } from './adminNotifications/adminNotifications.slice';
import { statusesReducer } from './statuses/statuses.slice';
import { adminTagsReducer } from './adminTags/adminTags.slice';
import { adminAnalyticsReducer } from './adminAnalytics/adminAnalytics.slice';

export const rootReducer = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  config: configReducer,
  widgets: widgetsReducer,
  user: userReducer,
  home: homeReducer,
  content: contentReducer,
  toast: toastReducer,
  faqs: faqsReducer,
  dsaTeams: dsaTeamsReducer,
  dsaInsights: dsaInsightsReducer,
  aiChats: aiChatsReducer,
  adminNotifications: adminNotificationsReducer,
  adminTags: adminTagsReducer,
  statuses: statusesReducer,
  adminAnalytics: adminAnalyticsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppStore = typeof store;
