import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ObjectIdString, WithObjectId } from '../../types';
import { AdminUpdateTag, Content } from '../../types/content.types';
import { CONTENT_TYPES, STATE_STATUSES } from '../../utils/constants';
import { RootState } from '../store';
import { fetchApplicationStateUpdates } from '../user/user.slice';
import { fetchStatuses } from '../statuses/statuses.slice';
import { fetchTags } from '../adminTags/adminTags.slice';
import contentAPI from './content.api';

export interface ContentInitState {
  statuses: {
    fetchAllContentByRole: STATE_STATUSES;
    updateContent: STATE_STATUSES;
  };
  data: {
    contentArray: WithObjectId<Content>[];
    contentMap: Record<ObjectIdString, WithObjectId<Content>>;
    tagsMap: Record<string, ObjectIdString[]>;
  };
  syncLookerStatus: STATE_STATUSES;
}

export const initialState: ContentInitState = {
  statuses: {
    fetchAllContentByRole: STATE_STATUSES.INITIAL,
    updateContent: STATE_STATUSES.INITIAL,
  },
  data: {
    contentArray: [
      {
        _id: '',
        source: { name: '' },
        idFromSource: '',
        title: '',
        folderPath: [],
        type: { name: CONTENT_TYPES.DASHBOARD },
        fieldRole: { name: '' },
        tags: [{ name: '' }],
        description: '',
        dateAdded: '',
        updatedAt: '',
        createdAt: '',
      },
    ],
    contentMap: {},
    tagsMap: {},
  },
  syncLookerStatus: STATE_STATUSES.INITIAL,
};

export const fetchAllContentByRole = createAsyncThunk(
  'content/fetchAllContentByRole',
  async (_, thunkAPI) => {
    const response = await contentAPI.fetchAllContentByRole(thunkAPI.signal);
    return response.data.content;
  }
);

export const updateContent = createAsyncThunk(
  'content/updateContent',
  async (contentPayload: AdminUpdateTag, thunkAPI) => {
    const response = await contentAPI.updateContent(
      contentPayload,
      thunkAPI.signal
    );
    await thunkAPI.dispatch(fetchTags());
    return response.data;
  }
);

export const syncLooker = createAsyncThunk(
  'content/syncLooker',
  async (_, thunkAPI) => {
    try {
      const response = await contentAPI.syncLooker(thunkAPI.signal);
      await thunkAPI.dispatch(fetchAllContentByRole());
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllContentByRole.pending, state => {
        state.statuses.fetchAllContentByRole = STATE_STATUSES.PENDING;
      })
      .addCase(fetchAllContentByRole.fulfilled, (state, action) => {
        const { contentMap, tagsMap } = getContentAndTagsMaps(action.payload);

        return {
          ...initialState,
          statuses: {
            ...initialState.statuses,
            fetchAllContentByRole: STATE_STATUSES.FULFILLED,
          },
          data: {
            ...initialState.data,
            contentArray: action.payload,
            contentMap,
            tagsMap,
          },
        };
      })
      .addCase(fetchAllContentByRole.rejected, (state, action) => {
        if (!action.meta.aborted) {
          // update status for `error.slice`
          state.statuses.fetchAllContentByRole = STATE_STATUSES.REJECTED;
        }
      })
      .addCase(syncLooker.pending, state => {
        state.syncLookerStatus = STATE_STATUSES.PENDING;
      })
      .addCase(syncLooker.fulfilled, state => {
        state.syncLookerStatus = STATE_STATUSES.FULFILLED;
      })
      .addCase(syncLooker.rejected, state => {
        state.syncLookerStatus = STATE_STATUSES.REJECTED;
      })
      .addCase(fetchStatuses.pending, state => {
        state.syncLookerStatus = STATE_STATUSES.INITIAL;
      })
      //update based on application state updates interval request
      .addCase(fetchApplicationStateUpdates.fulfilled, (state, action) => {
        const { content } = action.payload;

        if (content) {
          const { contentMap, tagsMap } = getContentAndTagsMaps(content);

          state.data = {
            ...state.data,
            contentArray: content,
            contentMap,
            tagsMap,
          };
        }
      })
      .addCase(updateContent.pending, state => {
        state.statuses.updateContent = STATE_STATUSES.PENDING;
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        const updatedContent = action.payload;
        const contents = state.data.contentArray.map(content => {
          if (content._id === updatedContent._id) {
            return updatedContent;
          } else {
            return content;
          }
        });

        const { contentMap, tagsMap } = getContentAndTagsMaps(contents);

        state.statuses.updateContent = STATE_STATUSES.FULFILLED;
        state.data = {
          ...state.data,
          contentArray: contents,
          contentMap,
          tagsMap,
        };
      })
      .addCase(updateContent.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.statuses.updateContent = STATE_STATUSES.REJECTED;
        }
      });
  },
});
const getContentAndTagsMaps = (content: WithObjectId<Content>[]) => {
  const contentMap: typeof initialState.data.contentMap = {};
  const tagsMap: typeof initialState.data.tagsMap = {};

  content.forEach(item => {
    contentMap[item._id] = item;
    item.tags.forEach(({ name }) => {
      tagsMap[name] = tagsMap[name] || [];
      tagsMap[name].push(item._id);
    });
  });

  return { contentMap, tagsMap };
};

export const contentReducer = contentSlice.reducer;

export const selectFetchAllContentByRoleStatus = (state: RootState) =>
  state.content.statuses.fetchAllContentByRole;

export const selectContentArray = (state: RootState) =>
  state.content.data.contentArray;

export const selectContentMap = (state: RootState) =>
  state.content.data.contentMap;

export const selectTagsMap = (state: RootState) => state.content.data.tagsMap;

export const selectSyncLookerStatus = (state: RootState) =>
  state.content.syncLookerStatus;

export const selectIsUpdateContentPending = (state: RootState) =>
  state.content.statuses.updateContent === STATE_STATUSES.PENDING;

export const selectIsUpdateContentRejected = (state: RootState) =>
  state.content.statuses.updateContent === STATE_STATUSES.REJECTED;
