import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ERROR_CODES } from '../../utils/constants';
import { fetchConfig } from '../config/config.slice';
import { fetchAllContentByRole } from '../content/content.slice';
import { fetchHome } from '../home/home.slice';
import { RootState } from '../store';
import { fetchUser } from '../user/user.slice';
import { RejectedAction, isRejectedActionError } from '../../types/state.types';

export interface ErrorState {
  message: string | null;
}

const initialState: ErrorState = {
  message: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.message = action.payload;
    },
  },
  extraReducers: builder => {
    // AppInit requests ONLY
    builder.addMatcher(
      (action): action is RejectedAction =>
        [
          fetchConfig.rejected.type,
          fetchUser.rejected.type,
          fetchHome.rejected.type,
          fetchAllContentByRole.rejected.type,
        ].includes(action.type),
      (state, action) => {
        // not rejected via AbortController (i.e., legitimate error)
        if (!action.meta.aborted) {
          if (isRejectedActionError(action.error)) {
            if (action.error.code !== ERROR_CODES.UNAUTHORIZED) {
              // if error is other than 401
              state.message = action.error.message;
            }
          }
        }
      }
    );
  },
});

export const errorReducer = errorSlice.reducer;

export const { setErrorMessage } = errorSlice.actions;

export const selectErrorMessage = (state: RootState) => state.error.message;
