import {
  EventType,
  AccountInfo,
  EventMessage,
  AuthenticationResult,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import axios from 'axios';
import AppInsights from '../applicationInsights';
import { isProd } from '../utils/isEnv';
import { AUTH_SERVICE, ROLES } from '../utils/constants';

// Auth services for MSAL and EasyAuth with common methods

////////////////////////////////////////////////////////////////
// MSAL
////////////////////////////////////////////////////////////////
export class PCA {
  private static _instance: PublicClientApplication;

  static get instance() {
    return PCA._instance;
  }

  static async init() {
    if (!PCA._instance) {
      PCA._instance = new PublicClientApplication({
        auth: {
          authority: process.env.REACT_APP_AUTH_LOGIN_ENDPOINT,
          clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
          redirectUri: window.location.origin,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
        telemetry: {
          application: {
            appName: 'DSA Hub UI',
            appVersion: '2.0.0',
          },
        },
      });
      await PCA._instance.initialize();
    }
  }
}

export const scopes = ['openid', 'email', 'User.Read', 'profile'];
const silentRequestDefaults: SilentRequest = {
  scopes,
};

export const getIdToken = async (account: AccountInfo | null) => {
  if (account) {
    // open MSAL library issue for acquiring idTokens:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
    const exp = account.idTokenClaims?.exp || 0;

    const request: SilentRequest = {
      ...silentRequestDefaults,
      account,
      forceRefresh: new Date(exp * 1000) <= new Date(),
    };

    return PCA.instance
      .acquireTokenSilent(request)
      .then(response => {
        return response.idToken;
      })
      .catch(error => {
        // Do not fallback to interaction when running outside the context of MsalProvider.
        // Interaction should always be done inside context.
        AppInsights.trackException(error);
        return null;
      });
  }

  return null;
};

export const getAccount = () => PCA.instance.getActiveAccount();

export const msalInit = async () => {
  await PCA.init();

  const accounts = PCA.instance.getAllAccounts();
  if (!PCA.instance.getActiveAccount() && accounts.length) {
    PCA.instance.setActiveAccount(accounts[0]);
  }

  PCA.instance.addEventCallback((event: EventMessage) => {
    const isAuthEvent = event.eventType === EventType.LOGIN_SUCCESS;
    // ||
    // event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    // event.eventType === EventType.SSO_SILENT_SUCCESS;
    if (isAuthEvent && event.payload) {
      const payload = event.payload as AuthenticationResult;
      // TODO: consider dispatching `roles` to redux store
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/migration-guide.md#updating-redux-store-integration--reacting-to-events
      PCA.instance.setActiveAccount(payload.account);
    }
  });
};

const msalGetAccessToken = async (account: AccountInfo | null) => {
  if (account) {
    const request: SilentRequest = {
      ...silentRequestDefaults,
      account,
    };

    return PCA.instance
      .acquireTokenSilent(request)
      .then(response => {
        return response.accessToken;
      })
      .catch(error => {
        // Do not fallback to interaction when running outside the context of MsalProvider.
        // Interaction should always be done inside context.
        AppInsights.trackException(error);
        return null;
      });
  }

  return null;
};

const msalGetIsUserLoggedIn = async () => {
  const account = getAccount();

  if (!account) return false;

  return true;
};

const msalLogin = async () => {
  try {
    await PCA.instance.loginRedirect({ scopes });
  } catch (error) {
    //caught error does not prevent user from being redirected to login; do not log in App Insights
  }
};

export const msalLogout = () => {
  return PCA.instance.logoutRedirect({
    account: getAccount(),
    postLogoutRedirectUri: '/',
  });
};

const msalGetUserRoles = () => {
  const account = getAccount();
  return Promise.resolve(
    (account?.idTokenClaims?.roles || [ROLES.NO_REPORTING]) as ROLES[]
  );
};

////////////////////////////////////////////////////////////////
// EasyAuth
////////////////////////////////////////////////////////////////
const easyAuthGetAccessToken = async () => {
  try {
    const response = await axios.get('/.auth/me');
    const { access_token } = response.data[0];
    return access_token as string;
  } catch (error) {
    AppInsights.trackException(error as Error);
    return null;
  }
};

const easyAuthGetIsUserLoggedIn = async () => {
  try {
    const response = await fetch('/.auth/me');
    return response.ok;
  } catch (error) {
    AppInsights.trackException(error as Error);
    return false;
  }
};

const easyAuthLogin = async () => {
  // Easy Auth handles login automatically but in LookerContentItemAuthWrapper if user is not logged in, it uses this method
  // Based on suggestion from MS team, adding .referrer will redirect user back to the page they were on before logging in
  window.location.href = '/.auth/login/aad?post_login_redirect_uri=.referrer';
};

export const easyAuthLogout = () => {
  // Redirect to Easy Auth logout endpoint
  window.location.href = '/.auth/logout';
};

const easyAuthGetUserRoles = async () => {
  try {
    const response = await axios.get('/.auth/me');
    const user = response.data[0];
    const roles = user.user_claims
      .filter((claim: { typ: string; val: string }) => claim.typ === 'roles')
      .map((claim: { typ: string; val: string }) => claim.val) as ROLES[];
    return roles;
  } catch (error) {
    AppInsights.trackException(error as Error);
    return [ROLES.NO_REPORTING];
  }
};

////////////////////////////////////////////////////////////////
// Shared methods
////////////////////////////////////////////////////////////////
const getAuthMethod = <T>(msalMethod: T, easyAuthMethod: T): T => {
  return process.env.REACT_APP_AUTH_SERVICE === AUTH_SERVICE.MSAL
    ? msalMethod
    : easyAuthMethod;
};

export const getIsUserLoggedIn = getAuthMethod(
  msalGetIsUserLoggedIn,
  easyAuthGetIsUserLoggedIn
);
export const login = getAuthMethod(msalLogin, easyAuthLogin);
export const logout = getAuthMethod(msalLogout, easyAuthLogout);

// Locally we don't have EasyAuth to get access token from.
// When using EasyAuth, the getAccessToken is only used for getting
// user's headshot for 'fetchHeadshot' (used for AIChat) in user.api.ts
// if there is no token, app will continue on with no errors in fetchHeadshot
export const getAccessToken = getAuthMethod(
  msalGetAccessToken,
  !isProd() ? () => Promise.resolve('') : easyAuthGetAccessToken
);

// Locally we don't have EasyAuth to get roles from so we will always return DEVELOPER
export const getUserRoles = getAuthMethod(
  msalGetUserRoles,
  !isProd() ? () => Promise.resolve([ROLES.DEVELOPER]) : easyAuthGetUserRoles
);
