import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ObjectIdString, WithObjectId } from '../../types';
import { INotification, User } from '../../types/user.types';
import { addReadNotifications, fetchUser } from '../user/user.slice';
import { toggleIsNotificationsOpen } from '../widgets/widgets.slice';

export interface ToastState {
  toasts: WithObjectId<INotification>[];
}

const initialState: ToastState = {
  toasts: [],
};

/**
 * Mark one toast as read
 */
export const markToastAsRead = createAsyncThunk(
  'toast/markToastAsRead',
  async (_id: ObjectIdString, thunkAPI) => {
    thunkAPI.dispatch(toastSlice.actions.dequeueToast(_id));
    thunkAPI.dispatch(addReadNotifications([_id]));
  }
);

/**
 * Toast reconciliation
 */
type ReconcileToastsPayload = ReturnType<typeof reconcileToasts>['payload'];

export const reconcileToasts = createAction(
  'toast/reconcile',
  (
    rootState: RootState,
    incomingUnreadNotifications: WithObjectId<INotification>[]
  ) => ({
    payload: {
      rootState,
      incomingUnreadNotifications,
    },
  })
);

/**
 * Slice
 */
export const toastSlice = createSlice({
  name: 'toastSlice',
  initialState,
  reducers: {
    /**
     * Used to display a new toast
     */
    enqueueToast: (
      state,
      action: PayloadAction<WithObjectId<INotification>>
    ) => {
      state.toasts.unshift(action.payload);
    },
    /**
     * Used to clear one toast
     */
    dequeueToast: (state, action: PayloadAction<ObjectIdString>) => {
      state.toasts = state.toasts.filter(toast => toast._id !== action.payload);
    },
    /**
     * Used to clear all toasts
     */
    dequeueAllToasts: state => {
      state.toasts = [];
    },
  },
  extraReducers: builder => {
    // if toast is visible and user clicks to open notifications menu, then clear toasts
    builder
      .addCase(
        toggleIsNotificationsOpen,
        (state, action: PayloadAction<boolean>) => {
          if (action.payload) {
            state.toasts = [];
          }
        }
      )
      .addCase(
        reconcileToasts,
        (state, action: PayloadAction<ReconcileToastsPayload>) => {
          state.toasts = reconcile(action.payload);
        }
      )
      .addCase(addReadNotifications.fulfilled, (state, action) => {
        // create read notifications map
        const readNotificationsMap = action.payload.readNotifications.reduce(
          (acc, cur) => {
            acc[cur._id] = true;
            return acc;
          },
          {} as Record<ObjectIdString, true>
        );

        // only keep toasts not in read notifications map
        state.toasts = state.toasts.filter(
          toast => !readNotificationsMap[toast._id]
        );
      })
      // matchers
      .addMatcher(
        action => action.type === fetchUser.fulfilled.type,
        (state, action: PayloadAction<User>) => {
          state.toasts = action.payload.unreadNotifications;
        }
      );
  },
});

/**
 * Helpers
 */
const reconcile = ({
  rootState,
  incomingUnreadNotifications,
}: ReconcileToastsPayload) => {
  const curUnreadNotificationsMap =
    rootState.user.data.unreadNotifications.reduce(
      (acc, cur) => {
        acc[cur._id] = true;
        return acc;
      },
      {} as Record<ObjectIdString, true>
    );

  const newIncomingUnreadNotifications = incomingUnreadNotifications.filter(
    ({ _id }) => !curUnreadNotificationsMap[_id]
  );

  const updatedCurToasts = rootState.toast.toasts.reduce((acc, { _id }) => {
    let updatedToast: WithObjectId<INotification> | undefined;

    // if toast exists, get incoming in case it has been updated
    if (
      (updatedToast = incomingUnreadNotifications.find(
        notification => notification._id === _id
      ))
    ) {
      // only pushing updated toast removes toasts for deleted notifications
      acc.push(updatedToast);
    }

    return acc;
  }, [] as WithObjectId<INotification>[]);

  return [...newIncomingUnreadNotifications, ...updatedCurToasts];
};

export const toastReducer = toastSlice.reducer;

export const { enqueueToast, dequeueAllToasts } = toastSlice.actions;

export const selectToasts = (state: RootState) => state.toast.toasts;
