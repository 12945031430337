import { AsyncThunk } from '@reduxjs/toolkit';

export interface RejectedActionError {
  message: string;
  code: string;
}

/**
 * Type guard function to check if an error is a `RejectedActionError`.
 *
 * @param error - The error to check.
 * @returns `true` if the error is a `RejectedActionError`, otherwise `false`.
 */
export const isRejectedActionError = (
  error: unknown
): error is RejectedActionError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    'code' in error
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingActionBase = ReturnType<GenericAsyncThunk['pending']>;

/**
 * Represents a pending action with a specific `arg` type.
 *
 * Extends the `PendingActionBase` type by omitting (and then redefining) the `meta` property to include an `arg` property of type `T`.
 *
 * @template T - The type of the `arg` in the `meta` property.
 */
export type PendingAction<T> = Omit<PendingActionBase, 'meta'> & {
  meta: Omit<PendingActionBase['meta'], 'arg'> & {
    arg: T;
  };
};

type FulfilledActionBase = ReturnType<GenericAsyncThunk['fulfilled']>;

/**
 * Represents a fulfilled action with specific `arg` and `payload` types.
 *
 * Extends the `FulfilledActionBase` type by omitting (and then redefining) the `meta` property to include an `arg` property of type `T`,
 * and by omitting (and then redefining) the `payload` property as type `P`.
 *
 * @template T - The type of the `arg in the `meta` property.
 * @template P - The type of the `payload`.
 */
export type FulfilledAction<T, P> = Omit<
  FulfilledActionBase,
  'meta' | 'payload'
> & {
  meta: Omit<FulfilledActionBase['meta'], 'arg'> & {
    arg: T;
  };
} & {
  payload: P;
};

type RejectedActionBase = ReturnType<GenericAsyncThunk['rejected']>;

/**
 * Represents a rejected action with a specific `arg` type.
 *
 * Extends the `RejectedActionBase` type by omitting (and then redefining) the `meta` property to include an `arg` property of type `T`,
 *
 * @template T - The type of the `arg` in the `meta` property.
 */
export type RejectedAction<T = unknown> = Omit<RejectedActionBase, 'meta'> & {
  meta: Omit<RejectedActionBase['meta'], 'arg'> & {
    arg: T;
  };
};
