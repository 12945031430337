import Tag from '../Tag/Tag';
import CardRow from '../CardRow/CardRow';
import { HomePageCardProps, CardsContainerProps } from '../../types/home.types';
import getFormattedDateStr from '../../utils/getFormattedDateStr';
import Card from '../Card/Card';
import {
  CARD_TITLES,
  EMPTY_STATE_MESSAGES,
  PATHS,
  SEARCHPARAM_KEYS,
  TUTORIAL_TARGETS,
} from '../../utils/constants';
import useHandleFavoriteOrPinOrAppOrContentClick from '../../hooks/useHandleFavoriteOrPinOrAppOrContentClick';
import EmptyCardStateMessage from '../EmptyCardStateMessage/EmptyCardStateMessage';
import SeeAllButton from '../SeeAllButton/SeeAllButton';
import DSAInsightsCard from '../DSAInsightsCard/DSAInsightsCard';
import { SEARCH_SORT_OPTIONS } from '../../types/sort.types';
import { useAppSelector } from '../../hooks/redux';
import {
  selectFavoritesInFlight,
  selectPinnedFavoritesInFlight,
} from '../../state/user/user.slice';

const cardClassnames = 'mb-8 w-full md:container p-4';

/**
 * Main container for mobile cards
 */
const MobileCardsContainer = ({
  navFeatureFlags,
  allFavoritesMap,
  pinnedFavoritesMap,
  favorites,
  pinnedFavorites,
  popularContent,
  dsaInsights,
  popularTags,
  contentMap,
  navigate,
}: CardsContainerProps) => {
  const favoritesInFlight = useAppSelector(selectFavoritesInFlight);
  const pinnedFavoritesInFlight = useAppSelector(selectPinnedFavoritesInFlight);
  const handleFavoriteOrPinOrAppOrContentClick =
    useHandleFavoriteOrPinOrAppOrContentClick();

  return (
    <div
      onClick={handleFavoriteOrPinOrAppOrContentClick}
      className='flex flex-col items-center'
    >
      <FavoritesMobileCard
        hasContent={favorites.length + pinnedFavorites.length > 0}
        classNames={cardClassnames}
        navigate={navigate}
      >
        {[...pinnedFavorites, ...favorites].slice(0, 5).map(_id => (
          <CardRow
            key={_id}
            {...{
              contentItem: contentMap[_id],
              isFavorite: true,
              isFavoriteButtonDisabled:
                !!favoritesInFlight[_id] || !!pinnedFavoritesInFlight[_id],
              isPinnable: true,
              isPinned: !!pinnedFavoritesMap[_id],
              isPinnedButtonDisabled:
                !!pinnedFavoritesInFlight[_id] || !!favoritesInFlight[_id],
            }}
          />
        ))}
      </FavoritesMobileCard>

      <PopularContentMobileCard
        hasContent={popularContent.length > 0}
        classNames={cardClassnames}
      >
        {popularContent.slice(0, 5).map(_id => (
          <CardRow
            key={_id}
            {...{
              contentItem: contentMap[_id],
              isFavorite: allFavoritesMap[_id],
              isFavoriteButtonDisabled:
                !!favoritesInFlight[_id] || !!pinnedFavoritesInFlight[_id],
            }}
          />
        ))}
      </PopularContentMobileCard>

      {navFeatureFlags.dsaInsights && (
        <DSAInsightsMobileCard
          hasContent={dsaInsights.length > 0}
          classNames={cardClassnames}
          navigate={navigate}
        >
          {dsaInsights.map((insight, i) => (
            <DSAInsightsCard
              key={i}
              to={`${PATHS.dsaInsightsFeed}/${insight.slug}`}
              image={insight.hero}
              date={getFormattedDateStr(insight.publishDate)}
              title={insight.title}
            />
          ))}
        </DSAInsightsMobileCard>
      )}

      <PopularTagsMobileCard
        hasContent={popularTags.length > 0}
        classNames={cardClassnames}
      >
        {popularTags.slice(0, 20).map(tag => (
          <Tag
            key={tag}
            {...{
              tag,
              classNames: 'bg-brand-tint-grey-1',
              onClick: () =>
                navigate(
                  `${PATHS.searchResults}?${SEARCHPARAM_KEYS.QUERY}=${tag}&${SEARCHPARAM_KEYS.SORT}=${SEARCH_SORT_OPTIONS.RELEVANCE}`
                ),
            }}
          />
        ))}
      </PopularTagsMobileCard>
    </div>
  );
};

/**
 * Favorites card
 */
export const FavoritesMobileCard = ({
  hasContent,
  classNames,
  children,
  navigate,
}: HomePageCardProps) => (
  <Card classNames={classNames}>
    <h2 className='ml-2 text-2xl' id={TUTORIAL_TARGETS.FAVORITES_CARD}>
      {CARD_TITLES.FAVORITES}
    </h2>
    {hasContent ? (
      <div className='mb-4 h-80'>{children}</div>
    ) : (
      <EmptyCardStateMessage message={EMPTY_STATE_MESSAGES.NO_FAVORITES} />
    )}
    {hasContent && (
      <div className='flex justify-end'>
        <SeeAllButton onClick={() => navigate(PATHS.favorites)} />
      </div>
    )}
  </Card>
);

/**
 * Popular content card
 */
export const PopularContentMobileCard = ({
  hasContent,
  classNames,
  children,
}: Omit<HomePageCardProps, 'navigate'>) => (
  <Card classNames={classNames}>
    <h2 className='ml-2 text-2xl'>{CARD_TITLES.POPULAR_CONTENT}</h2>
    {hasContent ? (
      <div className='flex flex-col overflow-hidden'>{children}</div>
    ) : (
      <EmptyCardStateMessage
        message={EMPTY_STATE_MESSAGES.NO_POPULAR_CONTENT}
      />
    )}
  </Card>
);

/**
 * DSA insights card
 */
export const DSAInsightsMobileCard = ({
  hasContent,
  classNames,
  children,
  navigate,
}: HomePageCardProps) => (
  <Card classNames={classNames}>
    <h2 className='mb-2 ml-2 text-2xl'>{CARD_TITLES.DSA_INSIGHTS}</h2>
    {hasContent ? (
      <div className='mb-4 grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-4'>
        {children}
      </div>
    ) : (
      <EmptyCardStateMessage message={EMPTY_STATE_MESSAGES.NO_DSA_INSIGHTS} />
    )}
    {hasContent && (
      <div className='flex justify-end'>
        <SeeAllButton onClick={() => navigate(PATHS.dsaInsightsFeed)} />
      </div>
    )}
  </Card>
);

/**
 * Popular tags card
 */
export const PopularTagsMobileCard = ({
  hasContent,
  classNames,
  children,
}: Omit<HomePageCardProps, 'navigate'>) => (
  <Card classNames={classNames}>
    <h2 className='mb-2 ml-2 text-2xl'>{CARD_TITLES.POPULAR_TAGS}</h2>
    {hasContent ? (
      <div className='flex flex-wrap gap-2'>{children}</div>
    ) : (
      <EmptyCardStateMessage message={EMPTY_STATE_MESSAGES.NO_POPULAR_TAGS} />
    )}
  </Card>
);

export default MobileCardsContainer;
