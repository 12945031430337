import { hubAPI } from '../../http';
import { AdminAnalyticsFavorites } from '../../types/adminAnalyticsFavorites.types';
import {
  AdminAnalyticsSearchKeywords,
  AdminAnalyticsSearchNGrams,
  AdminAnalyticsSearchFilter,
  AdminAnalyticsSearchContentEngagements,
  AdminAnalyticsSearchParentFolders,
} from '../../types/adminAnalyticsSearch.types';

/**
 * Fetch admin analytics favorites
 */
const fetchAdminAnalyticsFavorites = (signal: AbortSignal) =>
  hubAPI.get<AdminAnalyticsFavorites>('/api/admin/analytics/favorites', {
    signal,
  });

/**
 * Fetch admin analytics search keywords
 */
const fetchAdminAnalyticsSearchKeywords = (
  filters: AdminAnalyticsSearchFilter,
  signal: AbortSignal
) =>
  hubAPI.get<AdminAnalyticsSearchKeywords>(
    '/api/admin/analytics/searches/keywords',
    {
      params: filters,
      signal,
    }
  );

/**
 * Fetch admin analytics search n-grams
 */
const fetchAdminAnalyticsSearchNGrams = (
  filters: AdminAnalyticsSearchFilter,
  signal: AbortSignal
) =>
  hubAPI.get<AdminAnalyticsSearchNGrams>(
    '/api/admin/analytics/searches/nGrams',
    {
      params: filters,
      signal,
    }
  );

/**
 * Fetch admin analytics search content engagement
 */
const fetchAdminAnalyticsSearchContentEngagement = (
  filters: AdminAnalyticsSearchFilter,
  signal: AbortSignal
) =>
  hubAPI.get<AdminAnalyticsSearchContentEngagements>(
    '/api/admin/analytics/searches/content-engagement',
    {
      params: filters,
      signal,
    }
  );

/**
 * Fetch admin analytics search parent folders
 */
const fetchAdminAnalyticsSearchParentFolders = (
  filters: AdminAnalyticsSearchFilter,
  signal: AbortSignal
) =>
  hubAPI.get<AdminAnalyticsSearchParentFolders>(
    '/api/admin/analytics/searches/parent-folders',
    {
      params: filters,
      signal,
    }
  );

const adminAnalyticsAPI = {
  fetchAdminAnalyticsFavorites,
  fetchAdminAnalyticsSearchKeywords,
  fetchAdminAnalyticsSearchNGrams,
  fetchAdminAnalyticsSearchContentEngagement,
  fetchAdminAnalyticsSearchParentFolders,
};
export default adminAnalyticsAPI;
