import { ObjectIdString } from '../types';

const getFavoritesMap = (favorites: string[]) =>
  favorites.reduce(
    (acc, cur) => {
      acc[cur] = true;
      return acc;
    },
    {} as Record<ObjectIdString, true>
  );

export default getFavoritesMap;
